import { cva, type VariantProps } from "class-variance-authority";
import {
  type ComponentPropsWithoutRef,
  forwardRef,
  type MouseEvent,
  type ReactNode,
  useCallback,
  useId,
  useState,
} from "react";
import { RiEyeFill, RiEyeOffFill } from "react-icons/ri";
import { useField } from "remix-validated-form";

import { FieldError, FieldInfo } from "~/components/ui/forms/field-messages";
import { Label } from "~/components/ui/forms/label";
import { cn } from "~/utils/classnames";

export const inputStyles = cva(
  [
    "block w-full appearance-none rounded-full border border-grey-300 bg-white placeholder:text-grey-400",
    "aria-invalid:border-danger-500 aria-invalid:text-danger-700 aria-invalid:ring-danger-500 aria-invalid:placeholder:text-danger-300",
    "read-only:cursor-not-allowed read-only:opacity-60 disabled:cursor-not-allowed disabled:opacity-60",
  ],
  {
    variants: {
      size: {
        xs: "h-6 px-3 text-xs max-sm:text-base",
        sm: "h-8 px-4 text-sm max-sm:text-base",
        default: "h-10 px-4 text-sm max-sm:text-base",
        lg: "h-12 px-5 text-base max-sm:text-base",
      },
      iconPosition: {
        left: "pl-11",
        right: "pr-11",
      },
    },
    defaultVariants: {
      size: "default",
      iconPosition: "right",
    },
  }
);

const iconStyles = cva(
  "absolute inset-y-0 flex items-center justify-center text-xl text-grey-400",
  {
    variants: {
      size: {
        default: "",
        xs: "",
        sm: "",
        lg: "",
      },
      iconPosition: {
        left: "left-0 pl-4",
        right: "right-0 pr-4",
      },
    },
    defaultVariants: {
      size: "default",
      iconPosition: "right",
    },
  }
);

interface InputProps
  extends VariantProps<typeof inputStyles>,
    Omit<ComponentPropsWithoutRef<"input">, "size"> {
  label?: ReactNode;
  infoMessage?: ReactNode;
  errorMessage?: ReactNode;
  icon?: ReactNode;
  iconPosition?: "left" | "right";
  onIconClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  absoluteComponent?: ReactNode;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(function Input(
  {
    id,
    label,
    type = "text",
    infoMessage,
    errorMessage,
    icon,
    iconPosition = "right",
    onIconClick,
    absoluteComponent,
    size,
    className,
    ...props
  },
  ref
) {
  const genId = useId();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const handleIconClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      if (type === "password") {
        return setIsPasswordVisible((prev) => !prev);
      }
      if (!onIconClick) {
        return null;
      }
      return onIconClick(e);
    },
    [type, onIconClick]
  );

  type = isPasswordVisible ? "text" : type;

  icon = isPasswordVisible ? <RiEyeOffFill /> : type === "password" ? <RiEyeFill /> : icon;

  return (
    <div className={cn("group", className)} aria-disabled={props.disabled}>
      {label ? (
        <Label htmlFor={id || genId} required={props.required} inputSize={size}>
          {label}
        </Label>
      ) : null}
      <div className={cn("relative", label && "mt-1.5")}>
        <input
          id={id || genId}
          type={type}
          aria-invalid={!!errorMessage}
          aria-describedby={
            `${infoMessage ? `${id || genId}-info ` : ""}${errorMessage ? `${id || genId}-error` : ""}` ||
            undefined
          }
          className={cn(inputStyles({ size, iconPosition: icon ? iconPosition : null }))}
          ref={ref}
          {...props}
        />
        {icon ? (
          <div className={cn(iconStyles({ size, iconPosition }))}>
            {type === "password" || onIconClick || isPasswordVisible ? (
              <button
                type="button"
                onClick={handleIconClick}
                aria-label={
                  type === "password"
                    ? isPasswordVisible
                      ? "Cacher le mot de passe"
                      : "Afficher le mot de passe"
                    : `Valider le champ "${label || props.name}"`
                }
              >
                {icon}
              </button>
            ) : (
              icon
            )}
          </div>
        ) : null}
        {absoluteComponent ? absoluteComponent : null}
      </div>
      {infoMessage ? (
        <FieldInfo inputId={id || genId} inputSize={size}>
          {infoMessage}
        </FieldInfo>
      ) : null}
      {errorMessage ? (
        <FieldError inputId={id || genId} inputSize={size}>
          {errorMessage}
        </FieldError>
      ) : null}
    </div>
  );
});

export const ValidatedInput = forwardRef<HTMLInputElement, InputProps>(
  function ValidatedInput(props, ref) {
    const { error, getInputProps } = useField(props.name ?? "");

    return <Input ref={ref} {...props} {...getInputProps({ errorMessage: error, ...props })} />;
  }
);
